class JsCheck {
    static removeJavaScriptAlert() {
        let submitButton = document.getElementById('submit-button');
        let infoDiv = document.getElementById('browser-incompatible');
        if (infoDiv != null) {
            infoDiv.remove();
        }

        if (submitButton != null) {
            submitButton.disabled = false;
        }
    }
}

module.exports = JsCheck
