const DeviceSelection = require("./device-selection");

class AudioOutputSelection extends DeviceSelection {
    cookieName = 'selectedAudioOutputDeviceId';
    audioOutputDevice = null;

    getCurrentDeviceId() {
        return this.getDevice(this.cookieName);
    }

    registerRoomListeners() {
        super.registerRoomListeners();

        // load selected device

        // register listener
        this.room.on('trackAdded', track => this.handleOnTrackAdded(track));
    }

    updateOutputDevice(device) {
        this.audioOutputDevice = device;
        this.saveDevice(this.cookieName, device);

        this.updateTracks();
    }

    /**
     * Update the subscribed audio tracks to the new output (sink).
     * Therefore all tracks of all connected participants need to be updated.
     */
    updateTracks() {
        if (this.room == null) {
            return;
        }

        console.log('Updating Audio tracks...');
        for (const participant of this.room.participants.values()) {
            for (const audioTrack of participant.audioTracks.values()) {
                const track = audioTrack.track;

                const mediaElements = track.detach();
                mediaElements.forEach(mediaElement => mediaElement.remove());

                this.handleOnTrackAdded(track);
            }
        }
    }

    /**
     * Check if this track is an audio track and update the sink.
     * @param track
     */
    handleOnTrackAdded(track) {
        if (track.kind !== 'audio' || this.audioOutputDevice == null) {
            return;
        }

        console.log('handleOnTrackAdded')
        const audioElement = track.attach();
        audioElement.setSinkId(this.audioOutputDevice.deviceId).then(() => {
            document.body.appendChild(audioElement);
        });
    }

    shouldIncludeDevice(device) {
        return device.kind === 'audiooutput' && device.deviceId !== '';
    }
}

module.exports = AudioOutputSelection
