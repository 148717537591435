const DeviceSelection = require("./device-selection");

class InputDeviceSelection extends DeviceSelection {
    cookieName = null;

    triggerReselect() {
        super.triggerReselect();
        let currentDeviceId = this.getCurrentDeviceId();
        if (currentDeviceId === undefined || currentDeviceId === null) {
            return;
        }

        this.updateDevice({deviceId : currentDeviceId});
    }

    updateDevice(device) {
        this.saveDevice(this.cookieName, device);

        return this.buildOptionWithDeviceId(device.deviceId);
    }

    getCurrentDeviceId() {
        return this.getDevice(this.cookieName);
    }

    buildOptionWithDeviceId(deviceId) {
        return {deviceId: {exact: deviceId}}
    }

    getLocalParticipant() {
        return this.room != null ? this.room.localParticipant : null;
    }

    handleNewLocalTrack(localTrack) {
        const localParticipant = this.getLocalParticipant();

        // If we are already in the room, update the tracks we are publishing
        if (localParticipant != null) {
            this.removeCurrentTracks(localParticipant);
            const priorityValue = isInjest ? 'high' : 'standard';
            localParticipant.publishTrack(localTrack, {priority: priorityValue});
        }
    }

    getTracks(localParticipant) {
        return Array.from(this.localTracks(localParticipant)).map(publication => publication.track);
    }

    /**
     * Unpublish the current video tracks
     * @param localParticipant
     */
    stopCurrentTracks(localParticipant) {
        if (localParticipant == null) {
            return;
        }

        let enabled = true;
        const tracks = this.getTracks(localParticipant);
        tracks.forEach(track => {
            enabled = track.isEnabled;
            track.stop();
        });

        return enabled;
    }

    /**
     * Unpublish the current video tracks
     * @param localParticipant
     */
    removeCurrentTracks(localParticipant) {
        const tracks = this.getTracks(localParticipant);

        localParticipant.unpublishTracks(tracks);
        tracks.forEach((track) => {
            track.detach().forEach((detachedElement) => {
                detachedElement.remove();
            });
        });
    }

    localTracks(localParticipant) {
        return [];
    }
}

module.exports = InputDeviceSelection
