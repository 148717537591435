const { connect } = require('twilio-video');
const RoomTimer = require('./room-timer');
const RemoteVideoEvents = require('./remote-video-events');
const EventEmitter = require('events');

class TwilioManager {
    eventEmitter = new EventEmitter();
    statusOverlay = undefined;
    roomTimer = new RoomTimer(new EventEmitter());
    remoteMediaDiv = document.getElementById('remote-media-div');

    constructor(statusOverlay) {
        this.statusOverlay = statusOverlay;
    }

    connectToRoom(token, options) {
        connect(token, options)
            .then(room => this.onRoomJoined(room))
            .catch(error => this.onError(error));
    }

    onError(error) {
        console.log('[TwilioManager] Could not connect to the Room: ', error.name);
        console.log('[TwilioManager] Could not connect to the Room: ', error.message);
        this.statusOverlay.displayError(error);
    }

    onRoomJoined(room) {
        console.log(`[TwilioManager] Successfully joined a Room: ${room}`);
        console.log(`[TwilioManager] Media Region: ${room.mediaRegion}`);

        // start timer
        this.roomTimer.onRoomJoined();

        this.eventEmitter.emit(RemoteVideoEvents.RoomJoined, room);

        // hide the connecting overlay
        this.statusOverlay.hideConnectingOverlay();
        // display waiting screen for user
        this.statusOverlay.toggleUsernamePlaceholder(false);

        // participants in the room on page load
        room.participants.forEach(participant => this.onParticipantConnected(participant));

        this.registerListeners(room);
    }

    registerListeners(room) {
        // participant connection (after page load) and disconnection
        room.on('participantConnected', participant => {
            this.remoteMediaDiv.innerHTML = '';
            this.onParticipantConnected(participant);
        });
        room.on('participantDisconnected', participant => this.onParticipantDisconnected(participant));

        // general room stuff
        room.on('reconnecting', () => this.onReconnectAttempt());
        room.on('disconnected', () => this.onDisconnect());
    }

    onParticipantConnected(participant) {
        this.statusOverlay.toggleUsernamePlaceholder(true);
        console.log(`Participant "${participant.identity}" connected to the room.`);

        participant.on('trackSubscribed', track => this.onParticipantTrackSubscribed(track));
        participant.on('trackUnsubscribed', track => this.onParticipantTrackUnsubscribed(track));
    }

    onParticipantTrackSubscribed(track) {
        this.remoteMediaDiv.appendChild(track.attach());
    }

    onParticipantTrackUnsubscribed(track) {
        const attachedElements = track.detach();
        attachedElements.forEach((el) => el.remove());
    }

    onParticipantDisconnected(participant) {
        console.log(`Participant disconnected: ${participant.identity}`);
        this.remoteMediaDiv.innerHTML = '';
        this.statusOverlay.toggleUsernamePlaceholder(false);
    }

    onReconnectAttempt() {
        console.log('Would attempt a reconnect...');
    }

    onDisconnect() {
        console.log('Disconnected from room!');

        let error = {
            name: 'DisconnectedError',
            message: 'You are no longer connected to the Remote Video service!<br>' +
                'Please reload this page.<br>' +
                '<button class="btn btn-danger" onclick="window.location.reload()">Reload</button>'
        }

        this.statusOverlay.displayError(error);
    }
}

module.exports = TwilioManager
