const InputDeviceSelection = require('./input-device-selection');
const { createLocalAudioTrack } = require('twilio-video');

class AudioInputSelection extends InputDeviceSelection {
    cookieName = 'selectedAudioInputDeviceId';
    audioInputDevice = null;
    tracksEnabled = true;

    updateDevice(device) {
        let options = super.updateDevice(device);

        // stop current tracks to work around the firefox issue
        // https://stackoverflow.com/questions/59068146/navigator-mediadevices-getusermedia-api-rejecting-with-error-notreadableerror
        this.trackEnabled = this.stopCurrentTracks(this.getLocalParticipant());

        // create and publish new
        createLocalAudioTrack(options).then((localAudioTrack) => this.handleNewLocalTrack(localAudioTrack));
    }

    handleNewLocalTrack(localTrack) {
        super.handleNewLocalTrack(localTrack);

        if (!this.trackEnabled) {
            console.log('Muting input track after switch...');
            localTrack.disable();
        }
    }

    buildOptionWithDeviceId(deviceId) {
        return {deviceId: deviceId};
    }

    localTracks(localParticipant) {
        return localParticipant.audioTracks.values();
    }

    shouldIncludeDevice(device) {
        return device.kind === 'audioinput' && device.deviceId !== '';
    }
}

module.exports = AudioInputSelection
