class SocketEvents {
    static DashboardToSpeakerHeartbeat = 'ViewerHeartbeatEvent';
    static SpeakerToDashboardHeartbeat = 'SpeakerHeartbeatEvent';
    static SpeakerToDashboardFullStatusUpdate = 'FullStatusUpdateEvent';

    static DashboardToSpeakerChat = 'ViewerChatEvent';
    static SpeakerToDashboardChat = 'SpeakerChatEvent';
    static SpeakerToDashboardConnectionTime = 'SpeakerConnectionTimeEvent';
}

module.exports = SocketEvents
