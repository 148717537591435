class PrevNext {
    identity = null;
    remoteChannel = null;

    constructor(identity, remoteChannel) {
        this.identity = identity;
        this.remoteChannel = remoteChannel;

        this.addClickHandlers();
        this.listenForKeys();
    }

    completeXHR(btn) {
        setTimeout(() => {
            btn.classList.remove('active');
        }, 500);
    }

    updateSlide(direction) {
        switch (direction) {
            case 'p':
                this.handleSlide('prev-slide');
                break;
            case 'n':
                this.handleSlide('next-slide');
                break;

            default:
                break;
        }

        this.remoteChannel.whisper('SlideEvent', {
            user: this.identity,
            direction: direction
        });
    }

    handleSlide(btnId) {
        const btn = document.getElementById(btnId);
        if (btn === undefined || btn === null) {
            return;
        }
        btn.classList.add('active');
        this.completeXHR(btn);
    }

    addClickHandlers() {
        if (document.getElementById('prev-slide')) {
            document.getElementById('prev-slide').onclick = () => this.updateSlide('p');
        }
        if (document.getElementById('next-slide')) {
            document.getElementById('next-slide').onclick = () => this.updateSlide('n');
        }
    }

    listenForKeys() {
        document.onkeydown = (e) => {
            switch (e.code) {
                case 'ArrowLeft':
                case 'ArrowDown':
                    this.updateSlide('p');
                    break;

                case 'ArrowRight':
                case 'ArrowUp':
                    this.updateSlide('n');
                    break;

                default:
                    return; // exit this handler for other keys
            }
            e.preventDefault(); // prevent the default action (scroll / move caret)
        };
    }
}

module.exports = PrevNext
