<template>
    <div class="chat-message card mt-2">
        <div class="card-header">
            {{ senderDisplayName }}
        </div>
        <div class="card-body">
            <p class="chat-message-content">{{ content }}</p>
            <small class="text-secondary">{{ dateString }}</small>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {required: true},
        date: {required: true},
        sender: {default: "Studio"}
    },
    computed: {
        dateString: function () {
            return new Date(this.date).toLocaleTimeString()
        },
        senderDisplayName: function () {
            return this.sender.replace('&amp;', '&');
        }
    }
}
</script>
