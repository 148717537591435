const SocketEvents = require("../socket-events");

class Chat {
    room = null;
    remoteChannel = null;
    chatSender = null;
    chatApp = new Vue({
        el: '#chat-app',
        data : {
            messages : [],
            closed: false
        },
        computed : {
            chatWindow: function () {
                return this.messages.length > 0 && !this.closed
            }
        },
        methods : {
            addMessage(message) {
                this.messages.unshift(message);
                this.closed = false;
            },
            closeChat() {
                this.closed = true;
            }
        },
        mounted() {
            this.$el.classList.remove('d-none');
        }
    });

    constructor(room, remoteChannel) {
        this.room = room;
        this.remoteChannel = remoteChannel;

        this.listenForIncomingMessages();
    }

    listenForIncomingMessages() {
        this.remoteChannel.listenForWhisper(SocketEvents.DashboardToSpeakerChat, e => {
            this.handleChatMessage(e.user, e.message);
        });
    }

    handleChatMessage(user, message) {
        if (this.room.name !== user) {
            return false;
        }

        this.chatApp.addMessage(message);
        this.replyChatMessage(message)
    }

    replyChatMessage(message) {
        this.remoteChannel.whisper(SocketEvents.SpeakerToDashboardChat, {
            message: message,
            user: this.room.name,
            forward: true
        });
    }
}

module.exports = Chat
