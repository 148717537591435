class Mic {
    room = null;
    remoteChannel = null;
    muted = false;
    /** @type HTMLElement **/
    micButton = null;

    constructor(room, remoteChannel, autoMute) {
        this.room = room;
        this.remoteChannel = remoteChannel;

        this.handleMicrophone(autoMute);
    }

    handleMicrophone(autoMute) {
        this.micButton = document.getElementById('mute');

        this.micButton.classList.remove('d-none');
        this.micButton.onclick = () => this.onMuteButtonClick();

        if (autoMute) {
            this.onMuteButtonClick();
        }
    }

    onMuteButtonClick() {
        if (this.muted) {
            this.room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.enable();
            });
            this.micButton.classList.remove('btn-danger');
            this.micButton.innerHTML = '<i class="fas fa-microphone"></i> Microphone is on';
        } else {
            this.room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.disable();
            });
            this.micButton.classList.add('btn-danger');
            this.micButton.innerHTML = '<i class="fas fa-microphone-slash"></i> Microphone is off<br><small>(Click to unmute)</small>';
        }

        // toggle state & send event
        this.muted = !this.muted;
        this.sendMicrophoneUpdate(this.room, this.muted);
    }

    /**
     * Update the current mic state to the dashboard.
     * @param room
     * @param muted
     */
    sendMicrophoneUpdate(room, muted) {
        this.remoteChannel.whisper('MicrophoneStatusEvent', {
            user: room.name,
            muted: muted
        });
    }

    /**
     * Getter for mute state.
     * @returns {boolean}
     */
    isMuted() {
        return this.muted;
    }
}

module.exports = Mic
