const VideoInputSelection = require('./video-input-selection');
const AudioOutputSelection = require('./audio-output-selection');
const AudioInputSelection = require('./audio-input-selection');

class DeviceSelectionManager {
    videoInputSelection = new VideoInputSelection();
    audioInputSelection = new AudioInputSelection();
    audioOutputSelection = new AudioOutputSelection();

    selectors = [this.audioOutputSelection, this.audioInputSelection, this.videoInputSelection];

    buildLocalVideoOptions() {
        return this.videoInputSelection.buildOptions();
    }

    updateDevices() {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            for (const selector of this.selectors) {
                selector.handleMediaDevices(devices);
            }

            this.deviceApp.updateDevices(
                this.videoInputSelection.devices,
                this.audioInputSelection.devices,
                this.audioOutputSelection.devices
            );
        });
    }

    setRoom(room) {
        this.deviceApp.selectionManager = this;
        this.deviceApp.room = room;
        this.deviceApp.loadDevices();

        for (const selector of this.selectors) {
            selector.setRoom(room);
        }
    }

    deviceApp = new Vue({
        el: '#device-input-app',
        data : {
            videoDevices : [],
            audioInputDevices : [],
            audioOutputDevices : [],
            selectedVideoDevice: -1,
            selectedAudioInDevice: -1,
            selectedAudioOutDevice: -1,
            showInputMenu: false,
            localMedia: true,
            room: null,
            selectionManager: null
        },
        computed : {
            hasMultipleInputs: function () {
                // 2, because output devices are often empty
                return this.videoDevices.length + this.audioInputDevices.length + this.audioOutputDevices.length > 2;
            },
            displaySelection: function () {
                return this.hasMultipleInputs && this.showInputMenu
            }
        },
        methods : {
            isRoomSet() {
                return this.room != null;
            },
            loadDevices() {
                this.videoDevices = [];
                this.audioInputDevices = [];
                this.audioOutputDevices = [];

                this.selectionManager.updateDevices();
            },
            updateDevices(video, audioIn, audioOut) {
                this.videoDevices = video;
                this.audioInputDevices = audioIn;
                this.audioOutputDevices = audioOut;

                this.selectedVideoDevice = this.setSelectionIndex(this.videoDevices,
                    this.selectionManager.videoInputSelection.getCurrentDeviceId()
                );
                this.selectedAudioInDevice = this.setSelectionIndex(this.audioInputDevices,
                    this.selectionManager.audioInputSelection.getCurrentDeviceId()
                );
                this.selectedAudioOutDevice = this.setSelectionIndex(this.audioOutputDevices,
                    this.selectionManager.audioOutputSelection.getCurrentDeviceId()
                );
            },
            setSelectionIndex(array, deviceId) {
                for (let i = 0; i < array.length; i++) {
                    //console.log('array[i].deviceId = ' + array[i].deviceId + ' ?= ' + deviceId);
                    if (array[i].deviceId === deviceId) {
                        return i;
                    }
                }

                return -1;
            },
            toggleInputSelectionMenu() {
                this.showInputMenu = !this.showInputMenu;
            },
            toggleLocalMedia() {
                this.localMedia = !this.localMedia;

                if (!this.localMedia) {
                    document.getElementById('mute').classList.add('localMediaHidden');
                } else {
                    document.getElementById('mute').classList.remove('localMediaHidden');
                }
            },
            selectInput() {
                this.showInputMenu = false;

                if (this.selectedVideoDevice > -1) {
                    this.selectVideoInput(this.videoDevices[this.selectedVideoDevice]);
                }
                if (this.selectedAudioInDevice > -1) {
                    this.selectAudioInput(this.audioInputDevices[this.selectedAudioInDevice]);
                }
                if (this.selectedAudioOutDevice > -1) {
                    this.selectAudioOutput(this.audioOutputDevices[this.selectedAudioOutDevice]);
                }
            },
            selectVideoInput(device) {
                if (device === null ||device === undefined) {
                    return;
                }
                this.selectionManager.videoInputSelection.updateDevice(device);
            },
            selectAudioInput(device) {
                if (device === null ||device === undefined) {
                    return;
                }
                console.log('Setting new audio input: ' + device.deviceId);
                this.selectionManager.audioInputSelection.updateDevice(device);
            },
            selectAudioOutput(device) {
                if (device === null ||device === undefined) {
                    return;
                }
                console.log('Setting new audio output: ' + device.deviceId);
                this.selectionManager.audioOutputSelection.updateOutputDevice(device);
            },
            listenForKeys() {
                document.onkeydown = (e) => {
                    if (e.code === 'KeyS') {
                        this.showInputMenu = !this.showInputMenu;
                        e.preventDefault();
                    }
                };
            },
        },
        mounted() {
            this.listenForKeys();
        }
    });
}

module.exports = DeviceSelectionManager
