window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
class Dashboard {
    app = new Vue({
        el: '#vue-app',
        data : {
            participants : {},
            participantOrder : [],
            connecting: true,
            connectAttempt: 1,
            participantTimeout: 3,
            fullscreen: false,
        },
        methods : {
            addParticipant(user) {
                if (this.participants.hasOwnProperty(user)) {
                    // update online state
                    this.participants[user].online = true;
                    this.participants[user].heartbeat = this.currentTimestamp();
                    return false;
                }

                this.$set(this.participants, user, {
                    name : user,
                    muted : false,
                    online : true,
                    framerate: -1,
                    rtt: 0,
                    dimensions: null,
                    audiocodec: null,
                    mediaserver: 'Unknown',
                    browser: null,
                    os: null,
                    timeleft: 14400
                });

                this.participantOrder.push(user);
            },
            onTimeLeftUpdate(user, timeLeft) {
                this.updateProperty(user, 'timeleft', timeLeft);
            },
            updateMuteState(user, muted) {
                this.updateProperty(user, 'muted', muted);
            },
            updateBrowserState(user, browser) {
                this.updateProperty(user, 'browser', browser);
            },
            updateMediaServer(user, mediaServer) {
                this.updateProperty(user, 'mediaserver', mediaServer);
            },
            updateFrameRate(user, framerate) {
                this.updateProperty(user, 'framerate', framerate);
            },
            updateRtt(user, rtt) {
                this.updateProperty(user, 'rtt', rtt);
            },
            updateDimensions(user, dimensions) {
                this.updateProperty(user, 'dimensions', dimensions);
            },
            updateOs(user, os) {
                this.updateProperty(user, 'os', os);
            },
            updateAudioCodec(user, audioCodec) {
                this.updateProperty(user, 'audiocodec', audioCodec);
            },
            updateProperty(user, key, value) {
                if (!this.participants.hasOwnProperty(user)) {
                    return false;
                }
                this.participants[user][key] = value;
            },
            clearParticipants() {
                const currentTimestamp = this.currentTimestamp();

                for (let name in this.participants) {
                    if (currentTimestamp - this.participants[name].heartbeat > this.participantTimeout) {
                        this.participants[name].online = false;
                        console.log("Speaker " + name + " failed a heartbeat, marking offline!");
                    }
                }
            },
            currentTimestamp() {
                return Math.floor(Date.now() / 1000);
            },
            sortParticipants() {
                this.participantOrder.sort((a, b) => a.localeCompare(b));
            },
            toggleFullscreen() {
                this.fullscreen = !this.fullscreen;
                let element = document.getElementById('vue-app');

                if (this.fullscreen) {
                    element.style.maxWidth = "100%";
                    element.style.height = "100%";
                    this.enterFullscreen(element);
                } else {
                    element.style.maxWidth = null;
                    element.style.height = null;
                    this.exitFullscreen();
                }
            },
            enterFullscreen(element) {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen();
                }
            },
            exitFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            },
            onDrop(evt, to) {
                const speakerName = evt.dataTransfer.getData('speaker');
                const from = this.participantOrder.indexOf(speakerName);

                this.participantOrder.splice(to, 0, this.participantOrder.splice(from, 1)[0]);
                this.$emit('dragging-ended');
            },
            startDrag(evt, item) {
                evt.dataTransfer.dropEffect = 'move';
                evt.dataTransfer.effectAllowed = 'move';
                evt.dataTransfer.setData('speaker', item);

                this.$emit('dragging-over-enter', item);
            },
            enterDrag(evt, item) {
                this.$emit('dragging-over-enter', item);
            }
        },
        mounted() {
            if (typeof onVueMounted === "function") {
                onVueMounted();
            }
        },
        computed: {
            noParticipants: function () {
                return Object.keys(this.participants).length === 0
            },
        }
    });
}

module.exports = Dashboard
