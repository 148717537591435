class StatusOverlay {
    Browser;
    viewerTarget = document.getElementById('viewer-target');
    errorImage = document.getElementById('error-image');
    disconnectedError = document.getElementById('waiting-for');

    constructor(Browser) {
        this.Browser = Browser;
    }

    hideConnectingOverlay() {
        document.getElementById('placeholder').hidden = true;
    }

    toggleUsernamePlaceholder(hide) {
        if (this.viewerTarget !== undefined && this.viewerTarget !== null) {
            this.viewerTarget.hidden = hide;
        }
    }

    displayDisconnectedError(text) {
        if (this.disconnectedError !== undefined && this.disconnectedError !== null) {
            this.disconnectedError.innerHTML = text;
        }
    }

    displayError(error) {
        let errorMessage = error.message;

        if (error.name === 'NotAllowedError') {
            let browser = this.Browser.getBrowser();
            errorMessage = 'Please allow the <b>camera and microphone</b> permissions.<br>' +
                'In <b>' + browser + '</b> the dialog should look like the following image.';
            browser = browser.toLowerCase();

            this.errorImage.classList.remove('d-none');
            this.errorImage.src = '/images/browsers/' + browser + '.png';

            let browserArrow = document.getElementById('browser-arrow');
            browserArrow.classList.remove('d-none');
            browserArrow.classList.add(browser + '-arrow');
        }

        if (error.name === 'DisconnectedError') {
            this.displayDisconnectedError(errorMessage);
            this.toggleUsernamePlaceholder(false);
            return;
        }

        document.getElementById('connecting').innerText = 'Connection error';
        document.getElementById('loading-spinner').hidden = true;
        document.getElementById('error-message').innerHTML = errorMessage;
    }
}

module.exports = StatusOverlay
