class Browser {
    static userAgent = (navigator && navigator.userAgent || '').toLowerCase()
    static vendor = (navigator && navigator.vendor || '').toLowerCase();

    static getBrowser() {
        if (Browser.isOpera()) return 'Opera';
        else if (Browser.isChrome()) return 'Chrome';
        else if (Browser.isFirefox()) return 'Firefox';
        else if (Browser.isSafari()) return 'Safari';
        else if (Browser.isInternetExplorer()) return 'Internet Explorer';

        return 'Unknown'
    }

    static getOS() {
        const nAgt = navigator.userAgent;
        const nVer = navigator.appVersion;

        // system
        let os = 'Unknown';
        const clientStrings = [
            {s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/},
            {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
            {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
            {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
            {s: 'Windows Vista', r: /Windows NT 6.0/},
            {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
            {s: 'Android', r: /Android/},
            {s: 'Open BSD', r: /OpenBSD/},
            {s: 'Sun OS', r: /SunOS/},
            {s: 'Chrome OS', r: /CrOS/},
            {s: 'Linux', r: /(Linux|X11(?!.*CrOS))/},
            {s: 'iOS', r: /(iPhone|iPad|iPod)/},
            {s: 'macOS', r: /Mac OS X/},
            {s: 'UNIX', r: /UNIX/},
        ];
        for (const id in clientStrings) {
            const cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        let osVersion = '';

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

        switch (os) {
            case 'macOS':
            case 'Android':
                osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                break;
        }

        return os + ' ' + osVersion.replace(/_/g,".");
    }

    static isOpera() {
        const isOpera = this.userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
        return isOpera !== null;
    }

    static isChrome() {
        const isChrome = /google inc/.test(this.vendor) ? this.userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
        return isChrome !== null;
    }

    static isFirefox() {
        const isFirefox = this.userAgent.match(/(?:firefox|fxios)\/(\d+)/);
        return isFirefox !== null;
    }

    static isSafari() {
        const isSafari = this.userAgent.match(/version\/(\d+).+?safari/);
        return isSafari !== null;
    }

    static isInternetExplorer() {
        const isInternetExplorer = this.userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/);
        return isInternetExplorer !== null;
    }
// End Detecting browser helpers functions
}

module.exports = Browser
