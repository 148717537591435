/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
const RemoteVideoEvents = require('./remote-video-events');
const JsCheck = require('./js-check');
window.Dashboard = require('./dashboard');
const TwilioManager = require('./twilio-manager');
const PrevNext = require('./speaker/prev-next');
const Drag = require('./speaker/drag');
const Mic = require('./speaker/mic');
const Heartbeat = require('./speaker/heartbeat');
const Chat = require('./speaker/chat');
const DeviceSelectionManager = require('./io-selection/device-selection-manager');
const StatusOverlay = require('./statusOverlay');
const Browser = require('./browser');

let twilioManager = null;
let remoteChannel = null;
let mic = null;
let heartbeat = null;
let statusOverlay = null;
let chat = null;
let deviceSelection = null;

document.addEventListener('DOMContentLoaded', function() {
    JsCheck.removeJavaScriptAlert();
    boot();
}, false);

function boot() {
    if (typeof token === 'undefined' || typeof room === 'undefined' || token === '' || room === '') {
        console.log('Not running app.js since token or room are undefined.');
        return false;
    }

    // init overlay and hide the waiting screen
    statusOverlay = new StatusOverlay(Browser);
    statusOverlay.toggleUsernamePlaceholder(true);
    console.log('Initialized StatusOverlay.');
    remoteChannel = null;
    deviceSelection = new DeviceSelectionManager();
    twilioManager = new TwilioManager(statusOverlay);

    if (isInjest) {
        const channelName = 'oa_remote_video_remote_' + environment + '.' + speakerUserId;
        console.log('Connecting to socket.io channel: ' + channelName);
        remoteChannel = Echo.private(channelName);
    }

    console.log('Connecting to Twilio Room...');
    const options = {
        audio: true,
        name: room,
        region: region,
        video: videoSettings,
        maxAudioBitrate: maxAudioBitrate,
        maxVideoBitrate: maxVideoBitrate,
        bandwidthProfile: {
            video: {
                mode: 'presentation',
                dominantSpeakerPriority: 'high'
            }
        },
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }]
    };
    twilioManager.connectToRoom(token, options);
    twilioManager.eventEmitter.on(RemoteVideoEvents.RoomJoined, room => onRoomJoined(room));
    twilioManager.roomTimer.eventEmitter.on(RemoteVideoEvents.RoomTimerThreshold, time => onConnectionTimeEvent(time));

    function onRoomJoined(room) {
        deviceSelection.setRoom(room);
        if (!isInjest) {
            return;
        }

        room.localParticipant.videoTracks.forEach(publication => {
            const localMediaContainer = document.getElementById('local-media-tracks');
            if (localMediaContainer === undefined || localMediaContainer === null) {
                return;
            }
            publication.setPriority('high');
            localMediaContainer.appendChild(publication.track.attach());
        });

        heartbeat = new Heartbeat(room.name, remoteChannel);
        heartbeat.setRoom(room);
        mic = new Mic(room, remoteChannel, autoMute);
        chat = new Chat(room, remoteChannel);
        new Drag('local-media');
        new PrevNext(identity, remoteChannel);

        if (remoteChannel != null) {
            remoteChannel.listenForWhisper('ViewerJoinedEvent', e => {
                sendFullUpdate();
            });

            // always send full update on join
            sendFullUpdate();
        }

        setInterval(() => sendSessionUpdate(room), 1000 * 30);
    }

    function sendSessionUpdate(room) {
        axios.post('/session/update').then((response) => {
            let responseData = response.data;
            if (responseData.hasOwnProperty('data') && responseData.data.terminate === true) {
                console.log('Terminating session!');
                room.disconnect();
            }
        }, (error) => {
            console.log(error);
        });
    }

    function sendFullUpdate() {
        heartbeat.sendFullStatusUpdate(mic.isMuted(),
            Browser.getBrowser(), Browser.getOS(),
            twilioManager.roomTimer.timeLeft()
        );
    }

    function onConnectionTimeEvent(timeLeft) {
        // send time left update via socket if this is a speaker
        if (isInjest) {
            heartbeat.sendTimeLeftUpdate(timeLeft);
            return;
        }

        // alert backend about nearing timeout
        axios.post('/session/timeout/' + room + '/' + timeLeft);
    }
}
