const RemoteVideoEvents = require('./remote-video-events');

class RoomTimer {
    // https://www.twilio.com/docs/video/programmable-video-limits#room-and-participant-system-limits
    // max participant connected duration
    maxParticipantDuration = 4 * 60 * 60;

    // when should we start notifying about the session end
    static NotifyThreshold = 10 * 60;
    joinTimeStamp = 0;
    timer = undefined;
    eventEmitter = undefined;

    constructor(eventEmitter) {
        this.eventEmitter = eventEmitter;
    }

    onRoomJoined() {
        this.joinTimeStamp = this.currentTimeStamp();
        this.startTimer();
    }

    onRoomLeft() {
        clearInterval(this.timer);
    }

    startTimer() {
        this.timer = setInterval(() => this.onTimerTick(), 1000 * 30);
    }

    onTimerTick() {
        let timeLeft = this.timeLeft();
        if (timeLeft > RoomTimer.NotifyThreshold) {
            return;
        }

        this.eventEmitter.emit(RemoteVideoEvents.RoomTimerThreshold, timeLeft);
    }

    timeLeft() {
        let timePassed = this.currentTimeStamp() - this.joinTimeStamp;
        return Math.max(0, this.maxParticipantDuration - timePassed);
    }

    currentTimeStamp() {
        return Math.floor(Date.now() / 1000);
    }
}

module.exports = RoomTimer
