<template>
    <div class="col-xl-4 col-md-6 mb-4">
        <div :class="[borderColor, {shake : shake}, {dragging : dragged}]" class="card shadow py-2 participant">
            <div class="card-body">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div
                            :class="micTextColor" class="text-xs font-weight-bold text-uppercase mb-1">{{ name }}<br>
                            <small class="text-secondary">
                                Browser: <span class="no-case-modification"><b>{{ browser }}</b>
                                <span v-if="showOs">on <b>{{ os }}</b></span>
                                </span>
                            </small>
                            <div v-if="mediaConnected">
                                <small class="text-secondary">Media Server: <b>{{ mediaserver }}</b></small>
                            </div>

                            <button v-if="hasStats" @click="toggleDetails" class="btn btn-secondary btn-sm"><i class="fas fa-wifi"></i> {{ statsButtonText }}</button>
                            <div v-if="hasStats && details">
                                <hr class="no-margins">
                                <b class="text-secondary">Video</b>
                                <div v-if="showFrameRate">
                                    <small class="text-secondary">FPS & RTT: <span class="text-lowercase"><b>{{ framerate }}</b>FPS | <b>{{ rtt }}</b>ms</span></small>
                                </div>
                                <div v-if="showDimensions">
                                    <small class="text-secondary">Resolution: <span class="text-lowercase"><b>{{ dimensions.width }}</b>x<b>{{ dimensions.height }}</b>px</span></small>
                                </div>
                                <hr class="no-margins">
                                <b v-if="showAudioCodec" class="text-secondary">Audio</b>
                                <div v-if="showAudioCodec">
                                    <small class="text-secondary">Codec: <b>{{ audiocodec }}</b></small>
                                </div>
                            </div>
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800">
                            <div v-if="online && mediaConnected">
                                <p v-if="muted">Microphone muted</p>
                                <p v-else>Microphone active</p>

                                <p v-show="showTimeLeft" class="text-danger">Session timeout: <b>{{ timeLeft }}</b></p>
                            </div>
                            <div v-if="online && !mediaConnected">
                                <p>Connecting to media...</p>
                            </div>
                            <p v-if="!online">User is offline!</p>
                        </div>

                    </div>
                    <div class="col-auto">
                        <i v-if="online" :class="[micIcon, micTextColor]" class="fas fa-2x text-gray-300"></i>
                        <i :class="[connectedIcon, connectedTextColor]" class="fas fa-2x text-gray-300"></i>
                    </div>

                    <div v-if="online" class="input-group">
                        <input v-model="chatMessage" class="form-control" placeholder="Chat message" type="text"
                               @keyup.enter="submitChatMessage">
                        <button class="btn btn-primary ml-2" @click="submitChatMessage">
                            <i class="fas fa-paper-plane"></i>
                        </button>
                    </div>

                    <div v-if="shake" class="card card-body mt-2 text-center"><h4>{{ slideDirection }}</h4></div>
                    <div class="message-log mt-2">
                        <div v-for="message in messages" class="log-message">
                            <p class="chat-message-content"><b>{{ message.sender }}</b>: {{ message.content }}</p>
                            <small class="text-secondary">{{ message.date }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const {NotifyThreshold} = require("../room-timer");
export default {
    props: {
        name: {required: true},
        muted: {required: true},
        online : {default: true},
        browser : {default: 'Unknown'},
        os : {default: null},
        framerate : {required: true},
        rtt : {default: 0},
        dimensions : {default: null},
        audiocodec : {default: null},
        mediaserver : {default: 'n/a'},
        heartbeat : {required: true},
        timeleft : {required: true},
    },
    data : function () {
        return {
            chatMessage: '',
            shake: false,
            dragged: false,
            details: false,
            slideDirection : '',
            messages : []
        }
    },
    computed: {
        connectedIcon: function () {
            return this.online ? 'fa-user' : 'fa-user-slash'
        },
        connectedTextColor: function () {
            return this.online ? (this.mediaConnected ? 'text-success' : 'text-warning') : 'text-danger'
        },
        micIcon : function () {
            return this.muted ? 'fa-microphone-slash' : 'fa-microphone'
        },
        borderColor: function () {
            return 'border-left-' + this.color
        },
        micTextColor: function () {
            return 'text-' + this.color
        },
        color: function () {
            return (this.muted || !this.online) ? 'danger' : 'success';
        },
        mediaConnected: function () {
            return this.mediaserver != null;
        },
        hasStats: function () {
            return this.showFrameRate || this.showDimensions
        },
        showFrameRate: function () {
            return this.online && this.framerate > -1;
        },
        showAudioCodec: function () {
            return this.online && this.audiocodec != null;
        },
        showDimensions: function () {
            return this.online && this.dimensions != null;
        },
        showOs: function () {
            return this.os != null;
        },
        statsButtonText: function () {
            return this.details ? 'Hide stats' : 'Show stats';
        },
        showTimeLeft: function () {
            return this.timeleft <= NotifyThreshold;
        },
        timeLeft: function () {
            return Math.round(this.timeleft / 60) + 'm'
        }
    },
    methods: {
        submitChatMessage() {
            if (this.chatMessage === '') {
                return false;
            }

            if (typeof sendChatMessage === "function") {
                let id = sendChatMessage(this.name, this.chatMessage);
                this.addLogMessage({content: this.chatMessage, id: id, sender: 'You'});

                this.chatMessage = '';
            }
        },
        handleIncomingMessage(username, message) {
            if (this.name !== username || this.isDuplicateMessage(message.id)) {
                return false;
            }
            this.addLogMessage(message);
        },
        isDuplicateMessage(id) {
            for (const message of this.messages) {
                if (message.id === id) {
                    return true;
                }
            }
            return false;
        },
        addLogMessage(message) {
            message.date = new Date().toLocaleTimeString();
            this.messages.unshift(message);
        },
        shakeUser(username, direction) {
            if (this.name !== username) {
                return false;
            }

            this.slideDirection = direction === 'n' ? 'Next Slide ➡️' : '⬅️ Previous Slide';
            this.shake = true;

            this.addLogMessage({content: this.slideDirection, sender: this.name});

            setTimeout(() => this.disableShake(), 2000);
        },
        displayDropzone(username, state) {
            if (this.name !== username) {
                return false;
            }

            this.dragged = state;
        },
        hideDropzone() {
            this.dragged = false;
        },
        disableShake() {
            this.shake = false;
        },
        toggleDetails() {
            this.details = !this.details;
        }
    },
    created() {
        this.$root.$on('incoming-message', data => this.handleIncomingMessage(data.user, data.message));

        this.$root.$on('shake-participant', data => this.shakeUser(data.user, data.direction));
        this.$root.$on('dragging-over-enter', data => this.displayDropzone(data, true));
        this.$root.$on('dragging-over-left', data => this.displayDropzone(data, false));
        this.$root.$on('dragging-ended', () => this.hideDropzone());
    }
}
</script>
