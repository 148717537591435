const InputDeviceSelection = require('./input-device-selection');
const { createLocalVideoTrack } = require('twilio-video');

class VideoInputSelection extends InputDeviceSelection {
    cookieName = 'selectedCameraDeviceId';

    buildOptions() {
        let options = super.buildOptions();

        let selectedCamera = this.getCurrentDeviceId();
        if (selectedCamera !== undefined) {
            options = this.buildOptionWithDeviceId(selectedCamera);
        }

        return options;
    }

    shouldIncludeDevice(device) {
        return device.kind === 'videoinput' && device.deviceId !== '';
    }

    updateDevice(device) {
        let options = super.updateDevice(device);
        createLocalVideoTrack(options).then((localVideoTrack) => this.handleNewLocalTrack(localVideoTrack));
    }

    handleNewLocalTrack(localVideoTrack) {
        super.handleNewLocalTrack(localVideoTrack);

        // Update local preview
        this.appendNewTrack(localVideoTrack);
    }

    localTracks(localParticipant) {
        return localParticipant.videoTracks.values();
    }

    /**
     * Update own preview of local media.
     * @param localVideoTrack
     */
    appendNewTrack(localVideoTrack) {
        const localMedia = document.getElementById('local-media-tracks');
        if (localMedia === null) {
            return;
        }

        for (let child of localMedia.children) {
            if (child.tagName.toLowerCase() === 'video') {
                child.remove();
                break;
            }
        }
        localMedia.appendChild(localVideoTrack.attach());
    }
}

module.exports = VideoInputSelection
