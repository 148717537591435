const SocketEvents = require('./../socket-events')

class Heartbeat {
    user = '';
    room = null;
    remoteChannel = null;
    videoTrackStats = null;
    audioTrackStats = null;

    constructor(user, remoteChannel) {
        this.user = user;
        this.remoteChannel = remoteChannel;

        this.listenForHeartbeats();
    }

    setRoom(room) {
        this.room = room;
    }

    listenForHeartbeats() {
        this.remoteChannel.listenForWhisper(SocketEvents.DashboardToSpeakerHeartbeat, () => {
            this.respondHeartbeat();
        });
    }

    respondHeartbeat() {
        console.log('Responding to heartbeat...');

        this.room.getStats().then((statsReports) => {
            statsReports.forEach((statsReport) => {
                statsReport.localVideoTrackStats.forEach((videoTrackStats) => {
                    this.videoTrackStats = videoTrackStats;
                });
                statsReport.localAudioTrackStats.forEach((audioTrackStats) => {
                    this.audioTrackStats = audioTrackStats;
                });
            });
        });

        let fps = -1;
        let rtt = 0;
        let dimensions = { };
        let audioCodec = null;
        if (this.videoTrackStats != null) {
            fps = this.videoTrackStats.frameRate;
            rtt = this.videoTrackStats.roundTripTime;
            dimensions = this.videoTrackStats.dimensions;
        }

        if (this.audioTrackStats != null) {
            audioCodec = this.audioTrackStats.codec;
        }

        this.remoteChannel.whisper(SocketEvents.SpeakerToDashboardHeartbeat, {
            user: this.user,
            framerate: fps,
            rtt: rtt,
            dimensions: dimensions,
            audiocodec: audioCodec
        });
    }

    /**
     * Send a full update to a viewer (on join).
     * @param muted - If the participant is muted.
     * @param browser - The Browser the user joined with.
     * @param os - The operating system
     * @param timeLeft - Time left in the room
     */
    sendFullStatusUpdate(muted, browser, os, timeLeft) {
        console.log('Sending full status update...');

        this.remoteChannel.whisper(SocketEvents.SpeakerToDashboardFullStatusUpdate, {
            user: this.user,
            muted: muted,
            browser: browser,
            os: os,
            mediaserver: this.room == null ? null : this.room.mediaRegion,
            timeleft: timeLeft
        });
    }

    sendTimeLeftUpdate(timeLeft) {
        this.remoteChannel.whisper(SocketEvents.SpeakerToDashboardConnectionTime, {
            user: this.user,
            timeleft: timeLeft,
        });
    }
}

module.exports = Heartbeat
