const Cookies = require('js-cookie');

class DeviceSelection {
    devices = [];
    room = null;

    setRoom(room) {
        this.room = room;
        this.registerRoomListeners();

        setTimeout(() => this.triggerReselect(), 500);
    }

    triggerReselect() {}

    saveDevice(cookieName, device) {
        Cookies.set(cookieName, device.deviceId);
    }

    getDevice(cookieName) {
        return Cookies.get(cookieName);
    }

    buildOptions() {
        return {};
    }

    /**
     * Can be overridden to
     */
    afterRoomJoined() {}

    /**
     * Can be overridden to register this.room.on() listeners
     */
    registerRoomListeners() {}

    /**
     * Handle the incoming array of devices.
     * @param devices
     */
    handleMediaDevices(devices) {
        this.devices = [];

        for (let i = 0; i < devices.length; i++) {
            let device = devices[i];
            if (this.shouldIncludeDevice(device)) {
                this.addDevice(device);
            }
        }
    }

    /**
     * Add a new device to the local storage.
     * @param device
     */
    addDevice(device) {
        this.devices.push(device);
    }

    /**
     * Should be overridden to implement a check if the current device is applicable.
     * @param device
     * @returns {boolean}
     */
    shouldIncludeDevice(device) {
        return true;
    }
}

module.exports = DeviceSelection
